.wrapper {
  font-family: "Gotham" !important;
  height: 580px;
  width: 100%;
  border: 1px solid #d8d9da;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top_section {
  background-color: white;
  padding: 10px calc(7% + 5px);
  height: 185px;
  border-bottom: 1px solid #d8d9da;
}

.steps_section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.has-banner-above {
  height: calc(100% - 185px) !important;
  max-height: 364px;
  overflow: scroll;
}

.container {
  padding: 15px 7%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.blue-step {
  background-color: #d8eaff;
}

.calculator_wrapper {
  font-family: "Gotham" !important;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.top_section_calc {
  background-color: white;
  padding: 10px;
  border: 1px solid #d8d9da;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  text-align: left;
}

.MuiFormHelperText-root {
  height: 0 !important;
  margin: 0 !important;
}

.row {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.row-stretch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
  gap: 10px;
}

.middle {
  align-items: center;
}

.row-spacer {
  padding: 8px 0;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
}

input,
select {
  background-color: white !important;
  border-radius: 10px;
  font-family: "Gotham" !important;
}

.input {
  flex: 1;
  margin-right: 10px;
  font-family: "Gotham" !important;
}

.input:last-child {
  margin-right: 0;
}

.submit-button {
  flex: 1;
  margin-top: 30px;
  box-shadow: none !important;
  width: 100%;
}

.link {
  color: #1a1a1a;
  cursor: pointer;
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
}

.disclaimer {
  font-size: x-small;
  font-weight: 300;
  font-style: italic;
  color: #6e6e6e;
}

.title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 11px;
  font-weight: 300;
  font-style: normal;
  margin-bottom: 8px;
}

.label {
  font-size: 13px !important;
  margin-bottom: 2px;
  font-family: "Gotham" !important;
  color: #000 !important;
}

.error {
  text-align: center;
  font-size: small;
  color: red;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border-left-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.cars_grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.car_box {
  display: flex;
  flex-direction: column;
  flex: 1 0 50px; /* Grow, shrink, basis */
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: 1px solid #333;
  border-radius: 20px;
  padding: 30px 5px;
  background-color: white;
}

.image-item p {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.car_image_small {
  width: 200px;
  height: 100px;
  position: relative;
  object-position: center;
  margin-left: 20px;
}

.car_image {
  width: 250px;
  height: 150px;
  position: relative;
  object-position: center;
  margin-left: 20px;
}

.car_image_calc {
  width: 150px;
  height: 150px;
  position: relative;
  object-position: center;
  margin-left: 20px;
}

.car_image img,
.car_image_calc img,
.car_image_small img {
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: contain; /* or cover */
  object-position: center;
}

.big_number {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 5px;
}

.text-black {
  color: #1a1a1a;
}

.text-gray {
  color: #8b8c8f;
}

.text-scratch {
  text-decoration: line-through;
}

.mobile {
  display: none;
}

.send_bottom {
  position: absolute;
  bottom: 10px;
}

.primary-text {
  color: #000;
  text-align: center;
  font-family: Gotham;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-align: center;
  display: flex;
  place-content: center;
}

.header {
  display: flex;
  place-content: center;
  color: #000;
  text-align: center;
  font-family: Gotham;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

@media screen and (max-width: 568px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .top_section {
    height: 240px;
    padding: 15px 4%;
  }
  .wrapper {
    font-family: "Gotham" !important;
    border: 1px solid #d8d9da;
  }
  .left_menu {
    max-width: 100%;
    height: 100%;
  }
  .calculator_wrapper {
    flex-direction: column;
    overflow: visible;
  }
  .top_section_calc {
    padding: 5px calc(7% + 5px);
  }
  .row-mobile {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: flex-end;
  }
  .submit-button {
    min-width: 250px;
  }
  .container {
    padding: 15px 4%;
  }
  .car_image {
    margin: auto;
  }
  .middle {
    text-align: center;
  }
  .disclaimer {
    font-size: xx-small;
    margin: 0;
  }
  .car_image_small {
    width: 120px;
    height: 80px;
  }
}
